:root {
  --body-background-color: #243E7B;
  --btn-select-md-background-color: #F15A29;
  --btn-select-md-border: 2px solid #F15A29;
  --custom-button-2-background-color: #fff;
  --custom-button-2-color: #000;
  --herder-subject-background-color: #F15A29;
  --my-screen-background-color: '#243E7B';
  --logo-width: 150px;
  --logo-margin: 0px 20px 50px 50px;
  --custom-radio-dot-background-color: #F15A29;
  --custom-radio-dot-border-color: #F15A29;
  --custom-radio-dot-box-shadow: 0 0 0 0.2rem rgba(241, 90, 41, 0.2);
  --custom-button-signature-background-color: #F15A29;
  --modal-content-background-color: #243E7B
}

body {
  font-family: 'Prompt', sans-serif;
  font-weight: 400;
  background-color: var(--body-background-color);
}

.content-section {
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}

.content-header {
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24.18px;
}

.content-2 {
  font-family: 'Noto Sans Thai', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24.18px;
}

.content {
  font-family: 'Noto Sans Thai', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21.15px;
}

.custom-button {
  background-color: rgba(240, 90, 39, 1);
  color: #ffffff;
  border-radius: 5px;
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: box-shadow 0.3s ease-in-out;
}

/* Hover effect */
.custom-button:hover {
  background-color: gray;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Active state (optional) */
.custom-button:active {
  background-color: gray;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.sticky-div {
  position: sticky;
  top: 0px;
}

.custom-radio-dot .form-check-input:checked {
  background-color: var(--custom-radio-dot-background-color);
  border-color: var(--custom-radio-dot-border-color);
  box-shadow: var(--custom-radio-dot-box-shadow);
}

h1,
h2,
h3,
h4,
p {
  color: white
}

label {
  font-size: 16px;
  color: white;
}

/* COMPONENTS */
/*A1A1A1*/

.btn-primary {
  color: white;
  background-color: #A1A1A1;
  border-color: #A1A1A1;
  border-radius: 10px;
}

.btn-secondary {
  /* height: 40px; */
  color: white;
  background-color: #47BB11;
  border-color: #47BB11;
  border-radius: 10px;
}

.btn-secondary:hover {
  /* height: 40px; */
  color: white;
  background-color: #47BB11;
  border-color: #47BB11;
  border-radius: 10px;
}

.btn-danger {
  height: 40px;
  color: white;
  background-color: #FF3333;
  border-color: #FF3333;
  border-radius: 10px;
}

.btn-danger:hover {
  height: 40px;
  color: white;
  background-color: #FF3333;
  border-color: #FF3333;
  border-radius: 10px;
}

/* Default button style */
.btn-select-md {
  color: white;
  background-color: var(--btn-select-md-background-color);
  border: var(--btn-select-md-border);
  border-radius: 20px;
  font-size: 18px;
  width: 280px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
}

/* Hover effect */
.btn-select-md:hover {
  background-color: #A1A1A1;
  border-color: #A1A1A1;
}

/* Active state when the button is clicked */
.btn-select-md:active {
  background-color: #333333;
  border-color: #333333;
}

/* Add a subtle shadow to give the button depth */
.btn-select-md:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

/* Disable the button style */
.btn-select-md.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Additional states for a disabled button */
.btn-select-md.disabled:hover {
  background-color: #F15A29;
  border-color: #F15A29;
  color: white;
}

/* Button with a success state */
.btn-select-md.success {
  background-color: #4CAF50;
  border-color: #4CAF50;
}

/* Button with a warning state */
.btn-select-md.warning {
  background-color: #FFC107;
  border-color: #FFC107;
}

/* Button with a danger state */
.btn-select-md.danger {
  background-color: #FF5722;
  border-color: #FF5722;
  color: white;
}


.btn-select-sm {
  color: white;
  background-color: #F15A29;
  border-color: #F15A29;
  border-radius: 20px;
  font-size: 18px;
  width: 200px;
}

.btn-select-sm:hover {
  color: white;
  background-color: #A1A1A1;
  border-color: #A1A1A1;
  border-radius: 20px;
  font-size: 18px;
  width: 200px;
}


.form-label {
  font-size: 16px;
  color: white;
}

.form-control {
  border: 1px solid white;
  border-radius: 10px;
  font-weight: 300;
}

.form-select {
  border: 1px solid white;
  border-radius: 10px;
  font-weight: 300;
}

.navBar {
  font-size: 20px;
  font-weight: 300;
}

/* PAGE - LOGIN */

.loginBox {
  background-color: #243E7B;
  padding: 50px;
  text-align: center;
  vertical-align: middle;

  position: fixed;
  top: 0;
  left: 0;

  min-width: 100%;
  min-height: 100%;
}

.logo {
  margin: var(--logo-margin);
  width: var(--logo-width);
  height: auto;
}

.mlogo {
  width: 100px;
  padding: 10px 0px;
}

/* PAGE - HOME */

.yamGood {
  background-color: #DDFFD8;
  padding: 20px;
  border-radius: 6px;
  border: 3px solid #98D98E;
  margin-top: 20px;
  text-align: center;
}

.yamBad {
  background-color: #FFBDB4;
  padding: 20px;
  border-radius: 6px;
  border: 3px solid #FF6D59;
  margin-top: 20px;
  text-align: center;
}

.yamMid {
  background-color: #e8e4bb;
  padding: 20px;
  border-radius: 6px;
  border: 3px solid #f0de12;
  margin-top: 20px;
  text-align: center;
}

/* .duangBox {
  background-color: #2C2C2C;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid #B809CC;
  margin-top: 20px;
  text-align: left;
}

.profileImage {
  width: 100%;
  border-radius: 50%;
  border: 1px solid #B809CC;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.homeMenu {
  margin-top: 0px;
  border: 1px solid #B809CC;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */

/* PAGE REGISTER */

.pRegisPdpa {
  font-weight: 300;
  font-size: 12
}

/* .regisHeader {
  border: 1px solid #B809CC;
  padding: 20px;
  text-align: center;
} */

.regisLogo {
  margin: 10px;
  width: 40%;
  height: auto;
}

.container {
  background-color: #243E7B;
  padding: 30px;

  min-width: 100%;
  min-height: 100%;

  /* position: fixed;
  top: 0;
  left: 0; */
}

.wallpaper {
  background-color: #243E7B;
  padding: 30px;

  /* position: fixed;
  top: 0;
  left: 0; */

  min-width: 100%;
  min-height: 100%;
}

.regisReferralCodeText {
  padding-top: 10px;
  font-size: 12px;
  font-weight: 300;
}

.header {
  margin: auto;
  /* width: 50%; */
  /* padding: 10px; */
  text-align: center;
}

.btn-sml {
  width: 4vh;
  height: 4vh;
}

.modal-content {
  padding: 9px 15px;
  border-bottom: 1px solid #eee;
  background-color: var(--modal-content-background-color);
}

.btn-doc {
  color: white;
  background-color: white;
  border-color: white;
  border-radius: 10px;
  color: #000;
}

.btn-doc:hover {
  color: white;
  background-color: white;
  border-color: white;
  border-radius: 10px;
  color: #000;
}

.astext {
  position: absolute;
  /* left: 100px; */
  right: 50px;
}

/* Center point */
.center {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.right {
  text-align: right;
  display: block;
  justify-content: right;
  align-items: right;
  margin: auto;
}

.left {
  text-align: left;
  display: block;
  justify-content: left;
  align-items: left;
  margin: auto;
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  padding: 50px;
}

.footer2 {
  position: "absolute";
  left: 0px;
  right: 0px;
  top: 600px;
  width: 100%;
  padding: 50px;
}

.top {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px
}

.border-radius-0 {
  border-radius: 50;
}

.NoBounce {
  touch-action: manipulation;
}

.languageselect {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.languageselect img {
  max-height: 20px;
}

.languageselect div.flag:not(.selected) {
  display: none;
}

.languageselect div.flag.selected {
  display: block;
}

.languageselect:hover div.flag {
  display: block;
}

a {
  display: inline-block;
  position: relative;
}

a .image {
  display: block;
  width: 50px;
  height: 50px;
}

a .icon {
  position: absolute;
  right: 3px;
  top: 3px;
  line-height: 0;
}


input:disabled {
  color: black;
}


.mobile {
  background-color: #243E7B;
  padding: 30px;
  min-width: 100%;
  min-height: 100%;
}

/* BsFillCircleFill */
.status0-icon {
  color: #a9abad;
  border-radius: 50%;
}

/* BsFillCheckCircleFill */
.status1-icon {
  color: #a9abad;
  background-color: #292d31;
  border-radius: 50%;
}

.status2-icon {
  color: #cc898d;
  background-color: #6d0101;
  border-radius: 50%;
}

/* BsFillCheckCircleFill */
.status3-icon {
  color: #bbe4ab;
  background-color: #47bb11;
  border-radius: 50%;
}

.form-control:disabled {
  /* Add your desired styles here */
  background-color: #c5c5c5;
  border: 1px solid #c5c5c5;
  color: black;
  /* ... */
}

.form-select:disabled {
  /* Add your desired styles here */
  background-color: #c5c5c5;
  border: 1px solid #c5c5c5;
  color: black;
  /* ... */
}

#error-container {
  text-align: center;
  margin: 20% 10px 0 10px;
}

/* new */

.my-screen {
  max-width: 30rem;
  background-color: var(--my-screen-background-color);
  margin: 0 auto;
  padding: 40px;
}

.header-subject {
  background-color: var(--herder-subject-background-color);
  border-radius: 20px;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
}


/* CSS สำหรับปุ่ม */
.custom-button-signature {
  border-radius: 50px;
  color: white;
  border: 2px solid white;
  background-color: var(--custom-button-signature-background-color);
  cursor: pointer;
  border: none
}

/* เมื่อ hover ปุ่ม */
.custom-button-signature:hover {
  background-color: var(--custom-button-signature-background-color);
  color: black;
  background-color: white;
  border: 2px solid white;
  border: none
}

.form-control.is-valid,
.form-control.is-invalid {
  background-image: none;
  padding-right: inherit;
}


.back-icon {
  position: absolute;
  left: 10%;
  top: 46px;
  line-height: 0;
}

.forward-icon {
  position: absolute;
  right: 10%;
  top: 46px;
  line-height: 0;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* You can also add other styling as needed */
}

.blog img {
  vertical-align: middle;
}

.blog p {
  display: inline;
}

.custom-button-2 {
  background-color: var(--custom-button-2-background-color);
  color: var(--custom-button-2-color);
  border-radius: 5px;
  /* height: 37px; */
  padding: 6.5px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  /* background-color: rgb(1 192 171); */
  /* color: #fff; */
}

/* Hover effect */
.custom-button-2:hover {
  background-color: #fff;
}

/* Active state (optional) */
.custom-button-2:active {
  background-color: #fff;
}


/* Add this CSS to your stylesheet */
.custom-swal-container {
  width: 300px !important;
  /* Set the width to 300px */
  margin: 0 auto !important;
  /* Center horizontally */
}


.content-section {
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}

.content-header {
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24.18px;
}

.content-2 {
  font-family: 'Noto Sans Thai', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24.18px;
}

.content {
  font-family: 'Noto Sans Thai', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21.15px;
}

.custom-button {
  background-color: rgba(240, 90, 39, 1);
  color: #ffffff;
  border-radius: 5px;
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: box-shadow 0.3s ease-in-out;
}

/* Hover effect */
.custom-button:hover {
  background-color: gray;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Active state (optional) */
.custom-button:active {
  background-color: gray;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.sticky-div {
  position: sticky;
  top: 0px;
}

.custom-swal-html {
  font-size: 13px !important;
  color: rgba(255, 0, 0, 1) !important;
}

.custom-swal-html-green {
  font-size: 13px !important;
  color: rgba(40, 159, 59, 1) !important;
}

.custom-confirm-button {
  background-color: #ff5722 !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 10px 20px !important;
  width: 200px !important;
  font-size: 12px !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.button-test {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
  display: flex;
}